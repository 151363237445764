<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>订单中心</el-breadcrumb-item>
              <el-breadcrumb-item>发票中心</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_right">
                <div class="box_right_top">发票中心</div>
                <div class="box_right_cent">
                  <div class="invoice-box">
                    <div class="invoice-top">
                      <div class="flex-start">
                        <div class="flex-start">
                          <div class="invoice-text">订单编号</div>
                          <el-input v-model="numberNo"></el-input>
                        </div>
                        <div class="flex-start">
                          <div class="invoice-text" style="width:100px">下单时间</div>
                          <el-date-picker
                              v-model="value1"
                              type="datetime"
                              format="yyyy-MM-dd HH:mm:ss"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择日期时间"
                              :picker-options="pickerBeginDateBefore"
                          >
                          </el-date-picker>
                          <div class="zhi">至</div>
                          <el-date-picker
                              v-model="value2"
                              type="datetime"
                              format="yyyy-MM-dd HH:mm:ss"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择日期时间"
                              :picker-options="pickerBeginDateBefore"
                          >
                          </el-date-picker>
                        </div>
                        <div class="flex-start">
                          <div class="invoice-btn" @click="invoiceNum()">重置</div>
                          <div class="invoice-Btn" @click="invoiceBtn()">查询</div>
                        </div>
                      </div>
                      <div class="top-cent flex-start">
                        <div class="flex-start">
                          <div class="invoice-text">订单状态</div>
                          <el-select v-model="value" placeholder="请选择">
                            <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <div class="flex-start">
                          <div class="invoice-text">开票状态</div>
                          <el-select v-model="values" placeholder="请选择">
                            <el-option
                              v-for="item in option"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <!-- <div class="flex-start">
                          <div class="invoice-text">收货人</div>
                          <el-input></el-input>
                        </div> -->
                      </div>
                    </div>
                    <div class="invoice-cent">
                        <el-table
                        :data="tableData"
                        border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                        :cell-style="{ textAlign: 'center' }"
                        style="width: 100%;text-align: center;">
                            <el-table-column
                            prop="orderNo"
                            label="订单编号">
                            </el-table-column>
                            <el-table-column
                            prop="createTime"
                            label="下单时间">
                            </el-table-column>
                            <el-table-column
                            prop="orderState"
                            label="订单状态" width="180px">
                            <template slot-scope="scope">
                              {{
                                  orderState(
                                      scope.row.orderState
                                  )
                              }}
                            </template>
                            </el-table-column>
                            <!-- <el-table-column
                            prop="companyName"
                            label="发票抬头">
                            </el-table-column>
                            <el-table-column
                            prop="totalAmount"
                            label="订单总金额">
                            </el-table-column> -->
                            <!-- <el-table-column
                            prop="goodsNums"
                            label="商品种类总数" width="120px">
                            </el-table-column>
                            <el-table-column
                            prop="notInvoiceGoodsNums"
                            label="未开商品数量" width="120px">
                            </el-table-column> -->
                            <el-table-column
                            prop="invoiceState"
                            label="开票状态" width="180px">
                            <template slot-scope="scope">
                              {{
                                  invoiceState(
                                      scope.row.invoiceState
                                  )
                              }}
                            </template>
                            </el-table-column>
                            <el-table-column
                            prop="what"
                            label="操作" width="100px">
                            <template slot-scope="scope">
                              <div @click="invoiceDetails(scope.row)">
                                查看
                              </div>
                            </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="myorder-yema flex-center" style="margin-top:10px;"
                        v-show="tableData.length != 0"
                    >
                        <el-row
                            type="flex"
                            class="row-bg"
                            justify="space-between"
                        >
                            <el-col
                                :span="12"
                                style="text-align: right"
                            >
                                <el-pagination
                                    background
                                    @size-change="handleSizeChange"
                                    @current-change="
                                        handleCurrentChange
                                    "
                                    :current-page.sync="currentPage"
                                    :page-size="15"
                                    layout="prev, pager, next, jumper"
                                    :total="total"
                                >
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { invoiceCenter } from "@/api/order/order";
// import { applydetails } from "@/api/order/aftersale"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
         options: [{
          value: 0,label: '全部'
        }, {
          value: 1,label: '待付款'
        }, {
          value: 2,label: '待发货'
        }, {
          value: 3,label: '待收货'
        }, {
          value: 5,label: '已完成'
        },{
          value: 6,label: '已退款'
        },{
          value: 7,label: '已取消'
        }],
        option: [{
          value: 8,label: '全部'
        }, {
          value: 0,label: '未开票'
        }, {
          value: 1,label: '已开票'
        }],
        value: '',
        values: '',
        tableData: [],
        pageSize:15,
        total:0,
        currentPage:0,
        numberNo:'',
        value1:'',
        value2:'',
        model:0,
        pickerBeginDateBefore:{
          disabledDate(time) {
              return time.getTime() > Date.now();
          }
        },

      }
  },
  computed: {
    orderState() {
      return (orderState) => {
          switch (orderState) {
              case 1:
                  return "待付款";
              case 2:
                  return "待发货";
              case 3:
                  return "待收货";
              case 5:
                  return "已完成";
              case 6:
                  return "已退款";
          }
      };
    },
    invoiceState() {
      return (invoiceState) => {
          switch (invoiceState) {
              case 0:
                  return "未开票";
              case 1:
                  return "已开票";
              case 3:
                  return "作废";
              case 4:
                  return "已重开";
          }
      };
    },
  },
  created() {
    this.getInfor('','',1,'','','')
  },
  methods:{
    getInfor(endTime,invoiceState,val,orderNo,orderState,startTime){
      let data ={
        "endTime": endTime,
        "invoiceState": invoiceState,
        "myPage": {
          "pageNum": val,
          "pageSize": this.pageSize
        },
        "orderNo": orderNo,
        "orderState": orderState,
        "startTime": startTime,
      }
      invoiceCenter(data).then((data)=>{
        if(data&&data.code =='00000'){
          this.tableData=data.data.records
          this.total=data.data.total
        }
      })
    },
    handleSizeChange(val) {
      this.getInfor('','',val,'','','')
    },
    handleCurrentChange(val) {
      this.getInfor('','',val,'','','')
    },
    invoiceDetails(item){
      let data ={
        id: item.invoiceId
      }
      this.$router.push({ name: 'InvoiceDetails',query:{ item: JSON.stringify(data) } })
      // applydetails(data).then((data)=>{
      //   if(data&&data.code=='00000'){
      //       localStorage.setItem("prizeResult",JSON.stringify(data.data))
      //       this.$router.push({ name: 'InvoiceDetails',query:{item:JSON.stringify(data.data),model:this.model} })
      //   }
      // })
    },
    //重置
    invoiceNum(){
      this.value=''
      this.values=''
      this.numberNo=''
      this.value1=''
      this.value2=''
      this.getInfor('','',1,'','','')
    },
    //查询
    invoiceBtn(){
      if(this.value==0){
        this.value=''
      }
      if(this.values==8){
        this.values=''
      }
      let data = (new Date(this.value1)).getTime()
      let data2 = (new Date(this.value2)).getTime()
      if(data>data2){
          this.$message({
              type: "error",
              message: "开始时间不可以超过结束时间!",
          });
      }else{
        this.getInfor(this.value2,this.values,1,this.numberNo,this.value,this.value1)
      }
      
    }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_right{
    width: 87%;
    // height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .invoice-box{
      padding: 30px;
      .invoice-text{
        width: 30%;
      }
      .el-input{
        width: 50%;
        margin-right: 15px;
        margin-left: 15px;
      }
      .invoice-btn{
        width: 60px;
        padding: 0 15px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #d7d7d7;
        margin-right: 10px;
        
      }
      .invoice-Btn{
        width: 60px;
        padding: 0 15px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 5px;
        background: #CD9F49;
        color: #fff;
      }
      .top-cent{
        margin: 20px 0;
      }
    }
    
  }
}
</style>